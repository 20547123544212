import { blogAppDefId } from '../constants/apps';
import { TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import translation from './translation';

const addPages = async ({ sdk, appToken }) => {
  const [{ applicationId }, pages] = await Promise.all([
    sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId),
    sdk.pages.data.getAll(),
  ]);

  let postPageRef;

  if (pages) {
    pages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          [TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST].includes(page.tpaPageId),
      )
      .forEach(async (page) => {
        const data = { managingAppDefId: blogAppDefId };

        if (page.tpaPageId === 'post') {
          postPageRef = { id: page.id, type: page.type };
          data.title = translation.translate('app-manifest.post-page-label');
        }

        await sdk.pages.data.update(appToken, {
          pageRef: { id: page.id, type: page.type },
          data,
        });
      });
  }

  if (postPageRef) {
    await sdk.document.pages.setState(appToken, {
      state: {
        postPage: [postPageRef],
      },
    });
  }
};

export default { addPages };
